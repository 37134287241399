import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Contactimage from './Contactimage/Contactimage';

class Contact extends Component {
    constructor( props ) {
        super( props );
        this.isValid = false;
        this.onChange = ( e ) => {
            document.getElementById( 'submitbutton' )
                .removeAttribute( 'disabled' );
            this.isValid = true;
        };
        this.sendMail = ( e ) => {
            if (this.isValid === true) {
                this.props.submitfn( e );
            }

        };
    }

    render() {
        return (
            <div className="contact-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="d-none d-lg-block col-lg-6">
                            <div className="contactimage">
                                <Contactimage/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-6">
                            <div className="toptext">
                                <h4><a name="contact">Noch Fragen?</a></h4>
                                <hr className="hrleft"/>
                                Sie interessieren sich für zeit.report oder Ihnen ist aber noch etwas unklar?
                                Melden Sie sich bei uns! Wir freuen uns darauf, Ihnen zu helfen!
                                <br />&nbsp;<br />
                                <b>E-Mail:</b> <a href="&#109;&#x61;&#105;&#x6c;&#116;&#x6f;&#58;&#x7a;&#101;&#x69;&#116;&#x2e;&#114;&#x65;&#112;&#x6f;&#114;&#x74;&#64;&#x6e;&#52;&#x2e;&#100;&#x65;">Anfrage per E-Mail</a>
                                <br />
                                <b>Telefon:</b> +49 681 6 38 97 - 0
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default Contact;
