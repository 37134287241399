import React from 'react';

const reasons = () => {
    return (
        <div className="reasons-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div>
                            <div className="centered mixed ">
                                <h4>
                                    <a name="reasons">Zeiterfassung ohne Schnickschnack</a>
                                </h4>
                            </div>
                        </div>
                        <div className="col-sm-6 offset-md-3 centered top mixed">
                            Vorbei die Zeit händisch ausgefüllter, fehleranfälliger Tabellen.<br/>
                            Wechseln Sie jetzt zu zeit.report und machen Zeiterfassung <br/>
                            zu der leichtgängigen Tätigkeit, die sie sein soll.

                        </div>
                        <hr className="hrcenter"/>
                        <div className="row centered ">
                            <div className="col-lg-3 offset-lg-3 col-xs-12">
                                <div className="content">
                                    <ul>
                                        <li className="check1"><b>Kinderleicht bedienbar:</b> Nach wenigen Minuten Einrichtungs- und
                                            Einweisungszeit schnell und intuitiv zu bedienen
                                        </li>
                                        <li className="check2"><b>Flexibel:</b> Arbeits-, Urlaubs und Fehlzeiten auf einen Blick. Individuelle
                                            Pausen- und Arbeitszeitmodelle sowie Regel- und Schichtarbeit möglich. Nachträgliche
                                            Korrekturmöglichkeit beispielsweise durch die Personalabteilung
                                        </li>
                                        <li className="check1"><b>Umfangreich:</b> Verwalten Sie unterschiedliche Standorte, Zweigstellen,
                                            Abteilungen und behalten Sie dabei den Überblick
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-xs-12">
                                <div className="content">
                                    <ul>
                                        <li className="check2"><b>Gesetzeskonform:</b> Immer auf dem aktuellsten Stand, hilft Ihnen zeit.report
                                            mit seinen umfassenden Features, die gesetzlichen Anforderungen bei der Zeiterfassung zu
                                            erfüllen
                                        </li>
                                        <li className="check1"><b>BYOD (Bring Your Own Device):</b> Als leichtgewichtige Cloudlösung ist
                                            zeit.report von nahezu allen internetfähigen Endgeräten bedienbar
                                        </li>
                                        <li className="check2"><b>Transparent und erschwinglich:</b> Monatliche Laufzeit, ein Mitarbeiter – ein
                                            Preis, keine versteckten Zusatzkosten, übersichtliche Reports
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default reasons;
