import Home from './components/Home';
import Impressum from './components/Impressum';
import Datenschutz from './components/Datenschutz';

const impressum = [
    {
        path: '/',
        component: Home,
        exact: true
    },
    {
        path: '/impressum',
        component: Impressum,
        exact: true
    },
    {
        path: '/datenschutz',
        component: Datenschutz,
        exact: true
    }
];

export default impressum;
