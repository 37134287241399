import React from 'react';
import Testbutton from '../Testbutton/Testbutton';

const description = () => {
    return (
        <div className="description-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <div className="descriptionimage">
                            <img src="/public/Description.svg" id="descriptionimage" alt=""/>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="toptext">
                            <h4><a name="description">So flexibel, wie Sie es sind</a></h4>
                            <hr className="hrleft"/>
                            <h5>Aktuelle Zeiten auf allen Endgeräten</h5>
                            Egal ob feste Arbeitszeiten, Schicht- oder individuelle Arbeitszeitmodelle: zeit.report
                            erleichtert die Arbeitsorganisation sowohl für Arbeitgeber
                            als auch für Arbeitnehmer. In Echtzeit erhalten Sie per Smartphone, Tablet oder am Desktop-PC
                            Einsicht auf Arbeitszeiten, Urlaubs- und Krankentage.
                            Urlaubsanträge können bequem elektronisch übermittelt werden.
                            <h5>Einfach. Genau. Schnell</h5>
                            Mit zeit.report reichen wenige Klicks, damit die Zeiten
                            am Monatsende an den Steuerberater oder die Personalabteilung übermittelt werden.
                        </div>
                        <form action="#" method="post">
                            <fieldset>
                                <div className="form-group">
                                    <div className="col-md-4">
                                        <Testbutton/>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default description;
