import React from 'react';


class heroimage extends React.Component {
    constructor( props ) {
        super( props );
    }

    componentDidMount() {
        const uhrzeit = new Date();
        let secs = ( -1 ) * ( ( uhrzeit.getHours() * 60 * 60 ) + ( uhrzeit.getMinutes() * 60 ) +  ( uhrzeit.getSeconds() ) );
        document.getElementById( 'stundenzeiger' ).style[ 'animation-delay' ] = secs + 's';
        document.getElementById( 'minutenzeiger' ).style[ 'animation-delay' ] = secs - 1200 + 's';
    }
    render() {
        return (
            <div className="heroimage-wrapper">
                <svg id="heroimage" xmlns="http://www.w3.org/2000/svg" width="729.427" height="863.107"
                     viewBox="0 0 729.427 863.107">
                    <defs>
                        <linearGradient id="HeroA" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                            <stop offset="0" stopColor="#d062e5"/>
                            <stop offset="1" stopColor="#cd53e4"/>
                        </linearGradient>
                        <linearGradient id="heroD" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                            <stop offset="0.001" stopColor="#f98bec"/>
                            <stop offset="1" stopColor="#e950e6"/>
                        </linearGradient>
                        <linearGradient id="heroE" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                            <stop offset="0" stopColor="#beeada"/>
                            <stop offset="1" stopColor="#5cb8b2"/>
                        </linearGradient>
                        <linearGradient id="heroF" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                            <stop offset="0" stopColor="#fff"/>
                            <stop offset="1" stopColor="#beeada"/>
                        </linearGradient>
                        <linearGradient id="heroF2" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                            <stop offset="0" stopColor="#fff"/>
                            <stop offset="1" stopColor="#ddd"/>
                        </linearGradient>
                        <linearGradient id="heroN" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                            <stop offset="0" stopColor="#5cb8b2"/>
                            <stop offset="1" stopColor="#55a59d"/>
                        </linearGradient>
                        <linearGradient id="heroO" x1="-0.052" y1="0.5" x2="1.052" y2="0.5"
                                        gradientUnits="objectBoundingBox">
                            <stop offset="0" stopColor="#afe8d7"/>
                            <stop offset="1" stopColor="#8fd8cb"/>
                        </linearGradient>
                        <linearGradient id="heroP" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                            <stop offset="0" stopColor="#f98bec"/>
                            <stop offset="1" stopColor="#e950e6"/>
                        </linearGradient>
                        <linearGradient id="heroAE" x1="-0.366" y1="0.5" x2="1.366" y2="0.5"/>
                        <linearGradient id="heroAH" x1="-0.366" y1="0.5" x2="1.366" y2="0.5"/>
                        <linearGradient id="heroAN" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                            <stop offset="0" stopColor="#7820aa"/>
                            <stop offset="1" stopColor="#2d0c5d"/>
                        </linearGradient>
                        <filter id="light" width="200%" height="200%" x="-50%" y="-50%">
                            <feOffset in="SourceGraphic" dx="0" dy="0" result="offset"></feOffset>
                            <feComposite operator="in" in2="offset" result="stroke"></feComposite>
                            <feGaussianBlur stdDeviation="20" result="offsetblur"></feGaussianBlur>
                            <feFlood floodColor="#ffffff"></feFlood>
                            <feComposite operator="in" in2="offsetblur" result="blur"></feComposite>
                            <feMerge>
                                <feMergeNode in="blur"></feMergeNode>
                                <feMergeNode in="SourceGraphic"></feMergeNode>
                            </feMerge>
                        </filter>
                    </defs>
                    <g transform="translate(-652.112 -32.206)">
                        <circle id="heroid1" className="d" cx="4.548" cy="4.548" r="4.548" transform="translate(88 455.456)"/>
                        <circle id="heroid2" className="d" cx="10.926" cy="10.926" r="10.926" transform="translate(109 305.893)"/>
                        <circle id="heroid3" className="d" cx="2.909" cy="2.909" r="2.909" transform="translate(120 381.799)"/>
                        <circle id="heroid4" className="d" cx="5.445" cy="5.445" r="5.445" transform="translate(0 347.746)"/>
                        <circle id="heroid5" className="d" cx="1.945" cy="1.945" r="1.945" transform="translate(260 775.636)"/>
                        <circle id="heroid6" className="d" cx="2.823" cy="2.823" r="2.823" transform="translate(166 688.042)"/>
                        <circle id="heroid7" className="d" cx="4.88" cy="4.88" r="4.88" transform="translate(619 503.753)"/>
                        <circle id="heroid8" className="d" cx="6.043" cy="6.043" r="6.043" transform="translate(284 32.206)"/>
                        <circle id="heroid9" className="d" cx="2.755" cy="2.755" r="2.755" transform="translate(303 148.959)"/>
                        <circle id="heroid10" className="d" cx="2.058" cy="2.058" r="2.058" transform="translate(144 137.763)"/>
                        <circle id="heroid11" className="d" cx="1.418" cy="1.418" r="1.418" transform="translate(328 349.89)"/>
                        <circle id="heroid12" className="d" cx="2.44" cy="2.44" r="2.44" transform="translate(653.085 508.632)"/>

                        <path className="a"
                              d="M1306.693,833.659,1060.717,670.533c-15.384-8.882-27.855-32.71-27.855-53.223V80.439c0-20.512,12.471-29.94,27.855-21.058l256.82,148.275c15.384,8.882,27.855,32.711,27.855,53.223V795.212c0,13.5-5.049,19.124-13.54,24.026Z"/>
                        <path className="a"
                              d="M1164.429,329.317s-131.683,307.976-131.683,287.464l-21.63-542.886,32.28-17.047c4.736-2.232,10.711-1.745,17.2,2l256.82,148.275c7.512,4.337,14.329,12.238,19.339,21.575Z"/>
                        <path className="b"
                              d="M1291.667,831.915,1035.478,683.64c-15.384-8.882-27.224-32.71-27.224-53.223V93.546c0-20.512,12.145-29.94,27.529-21.058L1291.83,220.763c15.384,8.882,27.082,32.711,27.082,53.223V810.857C1318.912,831.369,1307.051,840.8,1291.667,831.915Z"/>
                        <path className="a"
                              d="M1286.53,833.818,1030.341,685.543c-15.384-8.882-27.225-32.711-27.225-53.223V95.449c0-20.512,12.145-29.94,27.529-21.058l256.047,148.275c15.384,8.882,27.082,32.711,27.082,53.223V812.76C1313.774,833.272,1301.914,842.7,1286.53,833.818Z"/>
                        <path className="c"
                              d="M1279.425,742.07,1029.639,597.855a8.4,8.4,0,0,1-4.2-7.271V137.2a8.4,8.4,0,0,1,12.594-7.272l249.787,144.215a8.4,8.4,0,0,1,4.2,7.271V734.8A8.4,8.4,0,0,1,1279.425,742.07Z"/>
                        <path className="d"
                              d="M1202.12,208.774,1122.9,163.036c-2.78-1.6-5.054-5.949-5.054-9.655h0c0-3.706,2.274-5.425,5.054-3.82L1202.12,195.3c2.779,1.6,5.053,5.949,5.053,9.655h0C1207.173,208.66,1204.9,210.379,1202.12,208.774Z"/>
                        <path className="e" d="M1253.722,616.546"/>
                        <path className="f"
                              d="M1331.215,349.274h0c-4.2,1.789-7.643-.456-7.643-4.99v-30.57a13.6,13.6,0,0,1,7.643-11.5h0c4.2-1.789,7.642.457,7.642,4.991v30.569A13.6,13.6,0,0,1,1331.215,349.274Z"/>
                        <path className="d"
                              d="M1334.495,349.684h0c-4.2,1.789-7.642-.456-7.642-4.99v-30.57a13.6,13.6,0,0,1,7.642-11.5h0c4.2-1.789,7.643.456,7.643,4.99v30.57A13.6,13.6,0,0,1,1334.495,349.684Z"/>
                        <path className="f"
                              d="M1331.215,404.918h0c-4.2,1.789-7.643-.456-7.643-4.99v-30.57a13.6,13.6,0,0,1,7.643-11.5h0c4.2-1.789,7.642.457,7.642,4.99v30.57A13.6,13.6,0,0,1,1331.215,404.918Z"/>
                        <path className="d"
                              d="M1334.495,405.328h0c-4.2,1.789-7.642-.457-7.642-4.991V369.768a13.6,13.6,0,0,1,7.642-11.5h0c4.2-1.789,7.643.456,7.643,4.99v30.57A13.6,13.6,0,0,1,1334.495,405.328Z"/>
                        <path className="g" d="M1145.021,583.693l-86.2-54.463.067-83.619,86.2,54.463Z"/>
                        <path className="h" d="M1268.181,611.713l-100.671-63.6.025-31.631L1269.1,580.649Z"/>
                        <path className="h" d="M1227.087,635.542l-59.616-37.665.025-31.631,58.2,36.767Z"/>
                        <path className="b"
                              d="M1100.571,539.019a3.736,3.736,0,0,1-3.46-2.322l-11.377-27.79a3.739,3.739,0,1,1,6.92-2.832l9.165,22.389,21.262-22.787a3.739,3.739,0,1,1,5.467,5.1L1103.3,537.832A3.738,3.738,0,0,1,1100.571,539.019Z"/>
                        <path className="g" d="M1144.2,468.209,1058,413.746l.067-83.619,86.2,54.463Z"/>
                        <path className="h" d="M1267.364,496.228l-100.671-63.6.025-31.631,101.569,64.171Z"/>
                        <path className="h" d="M1226.27,520.058l-59.616-37.665.025-31.631,58.2,36.767Z"/>
                        <path className="b"
                              d="M1099.753,423.535a3.738,3.738,0,0,1-3.46-2.322l-11.856-28.962a3.739,3.739,0,0,1,6.92-2.833L1101,412.979l21.263-22.786a3.738,3.738,0,0,1,5.467,5.1l-25.245,27.054A3.739,3.739,0,0,1,1099.753,423.535Z"/>
                        <path className="g" d="M1143.387,352.725l-86.2-54.463.067-83.619,86.2,54.463Z"/>
                        <path className="h" d="M1266.547,380.744l-100.671-63.6.025-31.631,101.569,64.171Z"/>
                        <path className="h" d="M1225.453,404.574l-59.616-37.665.025-31.631,58.2,36.767Z"/>
                        <path className="b"
                              d="M1098.937,308.051a3.736,3.736,0,0,1-3.46-2.322l-12.42-30.338a3.739,3.739,0,0,1,6.92-2.833l10.208,24.937,21.263-22.786a3.738,3.738,0,1,1,5.466,5.1l-25.244,27.054A3.743,3.743,0,0,1,1098.937,308.051Z"/>
                        <g className="i">
                            <ellipse className="j" cx="111.003" cy="166.395" rx="111.003" ry="166.395"
                                     transform="translate(846.843 469.927) rotate(-24.843)"/>
                        </g>
                        <path className="k"
                              d="M992.3,404.4c-32.133-18.086-63.181-21.037-88.675-11.323l-.011-.026-47,16.521,7.056,15.639c-10.629,15.474-17.94,35.4-20.8,59.177-10.2,84.887,40.171,186.7,112.5,227.414,15.315,8.62,30.379,13.773,44.721,15.792l3.971,8.8,31.477-10.568a80.92,80.92,0,0,0,10.715-3.6l.606-.2-.015-.036c30.441-12.927,52.448-44.4,57.947-90.178C1114.994,546.924,1064.627,445.108,992.3,404.4Z"/>
                        <ellipse className="l" cx="119.732" cy="179.48" rx="119.732" ry="179.48"
                                 transform="translate(751.416 458.168) rotate(-24.843)"/>
                        <ellipse className="b" cx="96.141" cy="144.116" rx="96.141" ry="144.116"
                                 transform="matrix(0.907, -0.42, 0.42, 0.907, 787.682, 480.348)"/>
                        <path className="m"
                              d="M942.71,693.647c-58.077-32.689-98.519-114.444-90.332-182.6,5.228-43.522,29.03-70.934,60.668-76.575-42.25-5.248-76.343,23.9-82.729,77.069-8.188,68.161,32.255,149.916,90.332,182.6,21,11.82,41.42,15.586,59.331,12.375C968.061,705.046,955.493,700.842,942.71,693.647Z"/>
                        <path className="n"
                              d="M948.323,465.715c-.111,5.767-3.891,10.525-8.444,10.627s-8.153-4.49-8.042-10.257,3.891-10.525,8.444-10.627S948.434,459.948,948.323,465.715Z"/>
                        <path className="n"
                              d="M938.544,679.014c-.111,5.767-3.892,10.525-8.444,10.627s-8.153-4.491-8.042-10.258,3.891-10.524,8.444-10.626S938.655,673.247,938.544,679.014Z"/>
                        <path className="n"
                              d="M1017.5,629.786c-4.948-2.691-7.1-8.162-4.813-12.221s8.156-5.168,13.1-2.477,7.1,8.162,4.814,12.221S1022.449,632.477,1017.5,629.786Z"/>
                        <path className="n"
                              d="M839.754,533.42c-4.948-2.69-7.1-8.162-4.813-12.221s8.157-5.168,13.105-2.477,7.1,8.162,4.813,12.221S844.7,536.111,839.754,533.42Z"/>
                        <path className="o" id="sekundenzeiger"
                              d="M977.935,656.113l-47.16-80.962a1.191,1.191,0,0,1,.368-1.651h0a1.231,1.231,0,0,1,1.634.521l47.16,80.963a1.191,1.191,0,0,1-.368,1.651h0A1.231,1.231,0,0,1,977.935,656.113Z"/>
                        <path className="n" id="minutenzeiger"
                              d="M935.233,576.6l-66.36,40.092a5.357,5.357,0,0,1-7.479-1.82h0a5.719,5.719,0,0,1,2.119-7.618l66.36-40.093a5.358,5.358,0,0,1,7.479,1.821h0A5.72,5.72,0,0,1,935.233,576.6Z"/>
                        <path className="p" id="stundenzeiger"
                              d="M940.378,517.456l-2.725,52.672a5.815,5.815,0,0,1-5.775,5.48h0a5.267,5.267,0,0,1-5.2-5.6l2.725-52.673a5.816,5.816,0,0,1,5.776-5.48h0A5.266,5.266,0,0,1,940.378,517.456Z"/>
                        <path className="p"
                              d="M941.761,574.326c-.11,5.757-4.655,8.853-10.151,6.915s-9.86-8.175-9.75-13.932,4.656-8.853,10.151-6.915S941.872,568.569,941.761,574.326Z"/>
                        <path className="b"
                              d="M936.239,571.794a3.211,3.211,0,0,1-4.581,3.121,6.987,6.987,0,0,1-4.4-6.288,3.211,3.211,0,0,1,4.581-3.12A6.986,6.986,0,0,1,936.239,571.794Z"/>
                        <path className="q"
                              d="M824.469,615.472h0a4.572,4.572,0,0,1,2.865-5.775l4.423-1.49a4.572,4.572,0,0,1,5.775,2.865h0a4.572,4.572,0,0,1-2.865,5.776l-4.422,1.489A4.572,4.572,0,0,1,824.469,615.472Z"/>
                        <path className="k"
                              d="M728.64,818.6l8.2-1.029c5.486-2.486,8.05-4.354,10.494-2.943l.692.4c2.444,1.411,2.135,3.9-.687,5.527l-12.053,9.576a7.956,7.956,0,0,1-8.875-.862s-1.349-1.217-.385-6.34C726.575,820.013,726.383,819.908,728.64,818.6Z"/>
                        <path className="k"
                              d="M758.447,829.591l9.218,1.736c6.793-.78,10.213-1.908,12.363.465l.61.673c2.151,2.374.952,4.954-2.663,5.734l-16.353,6.162a9.029,9.029,0,0,1-9.291-4.015s-1.035-1.784,1.787-6.985C755.727,830.4,755.556,830.215,758.447,829.591Z"/>
                        <path className="q"
                              d="M723.761,706.448,727,819.268c.2,7.176,8.49,4.634,9.427.246L738.491,714.9Z"/>
                        <path className="q"
                              d="M747.622,712.435l6.9,121.588c.318,7.6,8.294,5.01,9.125.373l1.984-113.38Z"/>
                        <path className="p"
                              d="M742.344,670.2l.372,3.107-21.748-3.852L723.1,798.994c7.515,10.735,16.508,2.147,16.508,2.147l2.454-96.6,3.829,1.928,5.235,110.82c7.8,8.961,13.552,0,13.552,0L772.67,670.2Zm.49,4.1.551,4.6-.659-.332Z"/>
                        <path className="q"
                              d="M697.912,681.478h0a4.573,4.573,0,0,1-3.115,5.646l-4.482,1.295a4.573,4.573,0,0,1-5.645-3.115h0a4.573,4.573,0,0,1,3.115-5.646l4.483-1.294A4.572,4.572,0,0,1,697.912,681.478Z"/>
                        <path className="g" d="M717.582,624.33,692.3,678.419l8.086,6.075,40.2-55.695Z"/>
                        <path className="q"
                              d="M756.921,595.331c-2.611,9.1-9.617,8.735-15.352,2.794s-8.028-15.207-5.417-24.307,9.377-11.661,15.112-5.72S759.531,586.231,756.921,595.331Z"/>
                        <path className="q"
                              d="M766.511,592.441c-2.611,9.1-12.366,12.393-18.1,6.453s-8.268-18.134-5.657-27.234,13.528-12.394,19.262-6.454S769.122,583.341,766.511,592.441Z"/>
                        <path className="q"
                              d="M742.922,620.57l-.8-.245c-2.689-.828-2.656-4.519-1.569-8.051l4.782-19.795c1.087-3.532,4.177-5.745,6.866-4.918l.8.245c2.689.827,4,4.395,2.914,7.927l-3.745,19.8C751.083,619.064,745.611,621.4,742.922,620.57Z"/>
                        <path className="p"
                              d="M735.716,590.593s.563,5,10.064,5.983c9.053.938,8.016-12.84,16.751-14.265,9.61-1.567,10.285-11.955,6.5-16.511s-14.37-6.939-22.036-3.558S730.888,568.071,735.716,590.593Z"/>
                        <path className="q"
                              d="M761.5,588.731a4.168,4.168,0,1,1-2.856-5.156A4.167,4.167,0,0,1,761.5,588.731Z"/>
                        <path className="g"
                              d="M720.481,628.726l-2.487,48.407s-3.156,9.1,22.584,15.926c21.149,5.61,33.169-3.94,34.4-6.329l-1.295-47.066,18.6,11.374a61.144,61.144,0,0,0-29.141-39c-4.491-2.536-11.136-5.575-16.567-7.942-8.069-3.517-11.738-2.249-19.186,2.442C723.41,609.042,720.481,628.726,720.481,628.726Z"/>
                        <path className="g"
                              d="M760.622,631.058,783.3,655.8a17.259,17.259,0,0,0,27.409-2.6l22.01-35.68-8.551-5.066-22.359,25.941a6.76,6.76,0,0,1-10.209.041c-6.612-7.57-17.774-19.794-21.517-20.5C764.641,616.907,760.622,631.058,760.622,631.058Z"/>
                        <path className="g"
                              d="M749.015,575.39c-.65,5.643-5.115,9.764-9.973,9.2s-8.268-5.588-7.617-11.232,5.116-9.764,9.973-9.2S749.666,569.747,749.015,575.39Z"/>
                        <path className="p"
                              d="M740.343,571.24c1.021-5.369-6.184-8.291-9.437-3.9a21.286,21.286,0,0,0-2.8,5.628c-5.362,15.3-31.567,9.975-21.772,39.118,0,0-13.9,16.777,7.17,26.951,3.38,1.632,7.567.267,11.723-2.376,10.5-6.675,15.132-19.408,12.6-31.586-.888-4.273-.953-8.813,1.067-12.475,2.559-4.641,3.609-10.073,1.928-15.1A12.26,12.26,0,0,1,740.343,571.24Z"/>
                        <path className="b"
                              d="M753.928,607.409s-7.625-5.978-13.35-5C740.578,602.405,740.917,610.067,753.928,607.409Z"/>
                        <path className="a"
                              d="M913.351,365.315c-6.023,3.478-15.819,3.462-21.88-.037s-6.092-9.155-.069-12.634,15.819-3.462,21.88.037S919.373,361.836,913.351,365.315Z"/>
                        <path className="p" d="M924.335,349.508l-44.061.7V303.377h44.061Z"/>
                        <path className="p"
                              d="M917.906,358.963c-8.6,5.011-22.551,5.043-31.164.071s-8.624-13.065-.026-18.077,22.55-5.043,31.163-.07S926.5,353.952,917.906,358.963Z"/>
                        <path className="a"
                              d="M924.407,316.126c-.312-.2-.632-.392-.962-.582-11.741-6.778-30.718-6.811-42.385-.072-.244.141-.48.284-.714.428v23.536c.276.172.557.343.848.511,11.741,6.778,30.717,6.81,42.385.071.284-.163.558-.33.828-.5Z"/>
                        <ellipse className="r" cx="17.306" cy="29.971" rx="17.306" ry="29.971"
                                 transform="matrix(0.005, -1, 1, 0.005, 872.186, 340.346)"/>
                        <rect className="p" width="59.941" height="11.049" transform="translate(872.278 312.673)"/>
                        <ellipse className="b" cx="17.306" cy="29.971" rx="17.306" ry="29.971"
                                 transform="matrix(0.005, -1, 1, 0.005, 872.186, 329.821)"/>
                        <path className="h"
                              d="M956.134,248.976c0-27.35-24.126-49.52-53.886-49.52s-53.886,22.17-53.886,49.52c0,14.975,7.234,28.4,18.665,37.477,6.254,6.876,9.112,15.337,10.416,21.457a17.2,17.2,0,0,0,11.259,12.7c9.282,3.19,18.241,3.581,26.783.364a16.887,16.887,0,0,0,10.557-12.436,44.629,44.629,0,0,1,10.35-21.04c.185-.2.35-.4.5-.594C948.656,277.816,956.134,264.2,956.134,248.976Z"/>
                        <ellipse className="s" cx="10.757" cy="18.63" rx="10.757" ry="18.63"
                                 transform="matrix(0.005, -1, 1, 0.005, 883.035, 317.691)"/>
                        <path className="g"
                              d="M904.915,311.086a1.4,1.4,0,0,1-1.4-1.4l-.007-36.616c-.16-.026-.321-.053-.481-.081a30.614,30.614,0,0,1-5.181-1.4v34.893a1.4,1.4,0,0,1-2.8,0V270.415a30.443,30.443,0,0,1-7.809-5.36c-3.877-3.688-6.359-8.055-6.81-11.982-.439-3.817,1.137-6.787,4.325-8.149,3.043-1.3,5.689-1.249,7.866.152,4.948,3.184,5.217,12.332,5.226,12.72v10.817a28.239,28.239,0,0,0,5.667,1.621l.187.032c.866-9.849,3-15.694,6.49-17.834a6.723,6.723,0,0,1,6.476-.159c4.979,2.16,7.364,6.683,6.077,11.524s-6.516,9.61-15.132,9.611q-.639,0-1.3-.036l.007,36.314A1.4,1.4,0,0,1,904.915,311.086Zm1.567-40.507c7.823.412,12.489-3.494,13.556-7.5.926-3.479-.793-6.635-4.485-8.236a4.039,4.039,0,0,0-3.9-.022C909.784,255.963,907.433,259.658,906.482,270.579Zm-17.638-23.793a7.711,7.711,0,0,0-2.992.712c-2.559,1.094-2.85,3.461-2.644,5.255.507,4.408,4.725,10.706,11.839,14.545v-9.451c-.059-2.245-.88-8.45-3.945-10.419A4.078,4.078,0,0,0,888.844,246.786Z"/>
                        <g className="t">
                            <ellipse className="b" cx="19.095" cy="13.797" rx="19.095" ry="13.797"
                                     transform="translate(855.45 252.882) rotate(-64.247)"/>
                        </g>
                        <path className="a"
                              d="M1259.972,888.889l-.782-19.224c.305-4.482,3.167-5.395,5.6-7.469a3.045,3.045,0,0,1,3.834-.052l2.448,1.9c3.625,2.617,3.2,7.473,2.544,10.566l-2.609,17.035C1270.35,894.737,1260.83,899.2,1259.972,888.889Z"/>
                        <path className="m"
                              d="M1272.379,863.554c0,4.568-11.108,4.577-11.112.009l-.012-14.847,11.112-.008Z"/>
                        <path className="a"
                              d="M1209.164,859.866l15.451-15.322c2.074-1.2,6.739-1.71,9.911-1.292a3.044,3.044,0,0,1,2.608,2.811l.231,3.09c.487,4.444.089,5.088-2.648,6.671l-16.253,9.4c-2.737,1.584-6.918,1.76-9.29.393l-.673-.388C1206.129,863.863,1206.427,861.449,1209.164,859.866Z"/>
                        <path className="m"
                              d="M1235.865,843.982c0,4.568-11.108,4.577-11.111.009l-.012-14.846,11.112-.009Z"/>
                        <path className="k"
                              d="M1273.667,854.133c-3.514,5.23-11.463,5.27-13.811,1.5L1236.474,699.7l40.1-.032Z"/>
                        <path className="k"
                              d="M1246.57,691.793l-34.514,10.265a717.9,717.9,0,0,0,11.327,137.136c2.408,3.633,9.609,3.7,13.961.571C1233.15,796.956,1240.461,747.584,1246.57,691.793Z"/>
                        <path className="q"
                              d="M1265.441,655.687l20.749,41.678c1.039,2.256,4.179,35.079,4.179,35.079l10.778,1.3s-3.168-44.436-3.8-46.679L1284.888,643.2Z"/>
                        <rect className="q" width="10.968" height="14.923" rx="4.77"
                              transform="translate(1288.841 730.528) rotate(-22.778)"/>
                        <path className="q"
                              d="M1210.75,634.28l-13.314,21.712c-2.434,4.81-7.717,13.585-9.485,23.54l-18.132-7.106.646,5.009,24.012,10.607c-2.1-11.585,5.011-12.5,9.055-17.39,7.1-8.585,14.143-17.151,14.216-17.521C1217.864,652.54,1210.75,634.28,1210.75,634.28Z"/>
                        <path className="u"
                              d="M1216.976,615.925l-15.595,29.29c2.559,10.184,8.084,15.363,15.059,18.094l5.9-39.606Z"/>
                        <path className="u"
                              d="M1210.669,698.258c2.864-15.771,3.816-33.633,1.2-55.4-.024-30.14,11.356-41.3,30.35-34.391l7.847,2.077c22.928,12.328,34.567,11.7,30.487,35.957l-3.972,53.17C1276.6,727.042,1209.235,730.435,1210.669,698.258Z"/>
                        <path className="a"
                              d="M1239.245,608.692s3,2.766,4.515,3.081,17.8-12.864,11.89-27.62c-3.741-9.346-21.4-2.009-21.4-2.009Z"/>
                        <path className="q"
                              d="M1244.634,621.584l-.911.155a5.693,5.693,0,0,1-6.55-4.64l-2.96-17.352a5.691,5.691,0,0,1,4.64-6.549l.911-.156a5.693,5.693,0,0,1,6.55,4.641l5.3,16.254C1252.135,617.014,1247.712,621.058,1244.634,621.584Z"/>
                        <path className="u"
                              d="M1263.659,649l11.479,32.454c1.627,2.518,25.045-9.356,23.041-13.224-.354-.682-12.855-39.884-26.271-46.2C1261.011,616.894,1257.628,634.214,1263.659,649Z"/>
                        <path className="v"
                              d="M1221.2,681.645l-4.405-1.548-.517-.519-.333-.589-33.187-17.224a1.7,1.7,0,0,0-1.471.059l-12.66,7.437-.023-.207-2.387.958.108.985h.017a.552.552,0,0,0,.265.255l34.536,17.924a.192.192,0,0,1,.09.134c.724,2.642,1.93,5.008,4.184,3.632l14.02-8.278a3.365,3.365,0,0,0,1.763-3.019Z"/>
                        <path className="w"
                              d="M1202.556,691.918l-39.77-20.64c-.461-.239-.452-.661.019-.938l16.886-9.919a1.954,1.954,0,0,1,1.693-.069l39.771,20.641c.46.239.451.661-.02.938l-16.886,9.919A1.954,1.954,0,0,1,1202.556,691.918Z"/>
                        <path className="v"
                              d="M1203.659,678.6l-9.625-5c-.111-.058-.109-.16,0-.227l4.086-2.4a.477.477,0,0,1,.41-.017l9.625,5c.112.058.11.16,0,.227l-4.087,2.4A.473.473,0,0,1,1203.659,678.6Z"/>
                        <path className="b"
                              d="M1199.05,668.657a4.51,4.51,0,0,0-2-2.492L1157.6,645.994a1.007,1.007,0,0,0-.838-.125h0l-.005,0a.462.462,0,0,0-.177.142l-1.1,1.237,1.11-.1,5.933,21.84a4.509,4.509,0,0,0,2,2.492l37.3,19.073,1.27,1.219.175.3c.728.147,2.306-1.01,2.306-1.01Z"/>
                        <path className="g"
                              d="M1202.8,692.978l-39.45-20.171a4.509,4.509,0,0,1-2-2.492l-6-22.081c-.256-.943.225-1.355,1.074-.92l39.45,20.171a4.505,4.505,0,0,1,2,2.492l6,22.081C1204.128,693,1203.648,693.412,1202.8,692.978Z"/>
                        <path className="b"
                              d="M1180.668,671.612c.318,1.173-.768,1.437-2.426.589a7.516,7.516,0,0,1-3.579-3.659c-.319-1.173.767-1.437,2.425-.589A7.523,7.523,0,0,1,1180.668,671.612Z"/>
                        <path className="q"
                              d="M1240.668,576.521c-3.778-3.371-7.722-3.142-10.383.005-3.845-.553-7.22,2.515-8.457,8.469-1.722,8.291,1.614,18.316,5.4,24.782,2.6,4.454,13.875,5.316,16.537,2,4.718.522,5.117-6.08,6.386-12.191C1251.868,591.291,1246.179,581.437,1240.668,576.521Z"/>
                        <path className="a"
                              d="M1237.944,582.212s.859,15.681,13.633,17.061c0,0,12.4-10.273-.208-23.37-11.112-11.543-23.689.808-23.689.808Z"/>
                        <path className="x"
                              d="M1221.367,588.942s3.128-6.138,11.691-7.045l-5.378-5.186S1220.954,576.682,1221.367,588.942Z"/>
                        <path className="a"
                              d="M1245.854,590.166c-3.477-3.329,3.058-3.619-5.256,1.347C1218.823,602.8,1217,585.826,1217,585.826c-2.155-4.788,4.283-9.586,9.071-11.74l8.964-3.642c7.048-3.217,15.231,1.636,17.386,6.424l.482.766C1255.061,582.423,1250.642,588.011,1245.854,590.166Z"/>
                        <path className="q"
                              d="M1252.5,601.443a3.728,3.728,0,1,1-2.892-4.408A3.729,3.729,0,0,1,1252.5,601.443Z"/>
                        <path className="y" d="M1236.74,613.78s7.287.985,12.309-7.922c0,0-4.683,10.9-11.481,11.842Z"/>
                        <path className="b"
                              d="M1297.809,574l-54.1-31.233c-4.2-2.424-7.6-8.926-7.6-14.523V495.865c0-5.6,3.4-8.17,7.6-5.746l54.1,31.233c4.2,2.424,7.6,8.926,7.6,14.523v32.376C1305.41,573.848,1302.007,576.421,1297.809,574Z"/>
                        <path className="b" d="M1257.1,548.623s7.864,23.6,8.135,22.943,13.983-13.371,13.983-13.371Z"/>
                        <path className="g" id="punkt1"
                              d="M1257.942,524.676c0,3.8-2.685,5.33-6,3.418a13.208,13.208,0,0,1-5.995-10.34c0-3.8,2.684-5.33,5.995-3.418A13.21,13.21,0,0,1,1257.942,524.676Z"/>
                        <path className="g" id="punkt2"
                              d="M1276.409,535.519c0,3.8-2.684,5.33-6,3.419a13.212,13.212,0,0,1-5.995-10.341c0-3.8,2.684-5.33,5.995-3.418A13.208,13.208,0,0,1,1276.409,535.519Z"/>
                        <path className="g" id="punkt3"
                              d="M1294.876,546.362c0,3.8-2.684,5.33-5.995,3.419a13.212,13.212,0,0,1-5.995-10.341c0-3.8,2.684-5.33,5.995-3.418A13.208,13.208,0,0,1,1294.876,546.362Z"/>

                    </g>
                </svg>
            </div>
        );
    }
}

export default heroimage;
