import React from 'react';
import Demoimage from './Demoimage/Demoimage';
import Vimeo from '@u-wave/react-vimeo'; // https://www.npmjs.com/package/@u-wave/react-vimeo

const demo = () => {
    return (
        <div className="demo-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="innerWrapper d-none d-sm-block">
                        <div className="innerBackgroundLow">

                        </div>
                        <div className="innerBackgroundHigh">
                            <Demoimage />
                        </div>
                        <div className="innerTop">
                            <h4><a name="demo">ZEIT.REPORT in Aktion</a></h4>
                            Sehen Sie, wie einfach Zeiterfassung heute sein kann.
                            <hr className="hrcenter" />
                        </div>
                        <div className="innerBottom">
                            <Vimeo
                                video="321329074"
                                autoplay={false}
                                responsive={true}
                                className="vimeovideo"
                            />
                        </div>
                    </div>
                    <div className="d-block d-sm-none col-sm-12">
                        <Vimeo
                            video="321329074"
                            autoplay={false}
                            responsive={true}
                            className="vimeovideo"
                        />
                    </div>
                </div>

            </div>
        </div>
    );
};

export default demo;
