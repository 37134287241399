import React from 'react';
import Testbutton from '../Testbutton/Testbutton';
import { Link } from 'react-scroll';

const pricing = () => {
    return (
        <div className="pricing-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-8 offset-sm-2 dark col-xs-12">
                        <h4 className="dark">
                            <a name="pricing" alt="">KLARE PREISE, KLARE SACHE</a>
                        </h4>
                        zeit.report ist nicht nur kinderleicht zu handhaben, auch die Preisgestaltung ist klar und verständlich.
                        <hr className="hrcenter dark"/>
                        <div className="row">
                            <div className="col-sm-8 offset-sm-2 dark col-xs-12 contentwrapper">
                                <div className="content">
                                    <img src="/public/Fist.svg" alt="" className="pricingimage"/>
                                    <h5>Unser Angebot</h5>
                                    <ul className="pricing-bullets">
                                        <li>1-5 Mitarbeiter 10,00 € / Monat</li>
                                        <li>6-10 Mitarbeiter 20,00 € / Monat</li>
                                        <li>2 € je weiteren Mitarbeiter / Monat</li>
                                    </ul>
                                    <Testbutton/>
                                    <Link to="contact" className="nav-link" smooth={ true } offset={ -70 } duration={ 500 }>
                                        <button className="btn form-control btn-primary gradientbutton">
                                            Kontaktieren Sie uns!
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-none col-sm-4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="442.963" height="600" viewBox="0 0 442.963 620">
                            <g>
                                <circle id="Pricingid1" className="a" cx="13.5" cy="13.5" r="13.5" transform="translate(330 125)"/>
                                <circle id="Pricingid2" className="b" cx="8.5" cy="8.5" r="8.5" transform="translate(90 185)"/>
                                <circle id="Pricingid3" className="b" cx="3.5" cy="3.5" r="3.5" transform="translate(95 60)"/>
                                <circle id="Pricingid4" className="b" cx="12" cy="12" r="12" transform="translate(220 160)"/>
                                <circle id="Pricingid5" className="a" cx="13.5" cy="13.5" r="13.5" transform="translate(280 345)"/>
                                <circle id="Pricingid6" className="a" cx="8.5" cy="8.5" r="8.5" transform="translate(10 50)"/>
                                <circle id="Pricingid7" className="b" cx="8.5" cy="8.5" r="8.5" transform="translate(165 30)"/>
                                <circle id="Pricingid8" className="a" cx="8.5" cy="8.5" r="8.5" transform="translate(185 115)"/>
                                <circle id="Pricingid9" className="b" cx="3.5" cy="3.5" r="3.5" transform="translate(370 265)"/>
                                <circle id="Pricingid10" className="a" cx="8.5" cy="8.5" r="8.5" transform="translate(365 450)"/>
                                <circle id="Pricingid11" className="b" cx="3.5" cy="3.5" r="3.5" transform="translate(280 520)"/>
                                <circle id="Pricingid12" className="b" cx="8.5" cy="8.5" r="8.5" transform="translate(340 40)"/>
                                <circle id="Pricingid13" className="b" cx="3.5" cy="3.5" r="3.5" transform="translate(90 50)"/>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default pricing;
