import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from '../routes';

class Layout extends React.Component {
    constructor() {
        super();
        this.state = {
            loc: ''
        };
    }

    render() {
        return (
            <div>
                <Route
                    render={ ( { location } ) => {
                        this.state.loc = location.pathname;
                        const { pathname } = location;
                        return (
                            <div>
                                <Route
                                    location={ location }
                                    render={ () => (
                                        <div>
                                            <Switch>
                                                { routes.map( route => (
                                                    <Route key={ route.path } { ...route } />
                                                ) ) }
                                            </Switch>
                                        </div>
                                    ) }
                                />
                            </div>
                        );
                    } }
                />
            </div>
        );
    }
}

export default Layout;
