import React from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';

const footer = ( props ) => {
    return (
        ( !props.context ) ?

            <div className="footer-wrapper">
                <div className="container">

                    <div className="row">
                        <div className="col-sm-2">
                            <img src="/public/Footer.png"/>
                        </div>
                        <div className="col-sm-2">
                            <div className="navigation">
                                <div className="navHeader">
                                    Navigation
                                </div>
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <Link to="description" className="nav-link" smooth={ true } offset={ -70 } duration={ 500 }>Funktionen</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="reasons" className="nav-link" smooth={ true } offset={ -70 } duration={ 500 }>Details</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pricing" className="nav-link" smooth={ true } offset={ -70 } duration={ 500 }>Preise</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="contact" className="nav-link" smooth={ true } offset={ -70 } duration={ 500 }>Kontakt</Link>
                                    </li>
                                </ul>

                            </div>
                        </div>

                        <div className="col-sm-2">
                            <div className="navigation">
                                <div className="navHeader">
                                    Rechtliches
                                </div>
                                <ul className="navbar-nav">
                                    <li><a className="nav-link" href="/impressum">Impressum</a></li>
                                    <li><a className="nav-link" href="/datenschutz">Datenschutzerklärung</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6">

                        </div>
                    </div>
                </div>
            </div>
            :
            <div className="footer-wrapper">
                <div className="container">

                    <div className="row">
                        <div className="col-sm-2">
                            <img src="/public/Footer.png"/>
                        </div>
                        <div className="col-sm-2">
                            <div className="navigation">
                                <div className="navHeader">
                                    Navigation
                                </div>
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <a href="/#description" className="nav-link">Funktionen</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="/#reasons" className="nav-link">Details</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="/#pricing" className="nav-link">Preise</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="/#contact" className="nav-link">Kontakt</a>
                                    </li>
                                </ul>

                            </div>
                        </div>

                        <div className="col-sm-2">
                            <div className="navigation">
                                <div className="navHeader">
                                    Rechtliches
                                </div>
                                <ul className="navbar-nav">
                                    <li><a className="nav-link" href="/impressum">Impressum</a></li>
                                    <li><a className="nav-link" href="/datenschutz">Datenschutzerklärung</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6">

                        </div>
                    </div>
                </div>
            </div>
    );
};
export default footer;
