import React, { Component } from 'react';
import Footer from './Footer/Footer';
import Navigation from './Navigation/Navigation';

class Impressum extends Component {
    constructor( props ) {
        super( props );
        this.state = {};
    }

    render() {
        return (
            <div className="impressum-wrapper">
                <Navigation context="impressum"/>
                    <div className="container">
                        <h2>Angaben gemäß § 5 TMG:</h2>
                        <p>
                            <b>
                                netRapid GmbH & Co. KG<br/>
                                n-Systems GmbH & Co. KG<br/>
                                NEXNOVA Business GmbH & Co. KG<br/>
                                NEXNOVA Software GmbH & Co. KG<br/>
                            </b>
                        </p>
                        <p>Kaiserstraße 95-101a<br/>
                            66133 Saarbrücken<br/>
                            Germany<br/>
                            email: info@n4.de</p>
                        <p>
                            <i>Vertreten durch:</i> <br/>
                            Vertreten durch die jeweiligen Komplementärinnen-GmbHs (netRapid Geschäftsführungs GmbH, n-Systems
                            Geschäftsführungs GmbH, nexnova Geschäftsführungs GmbH), die ihrerseits vertreten werden durch ihren
                            Geschäftsführer Mesut Cengiz
                        </p>
                        <br/>
                            <p><b>N4 Leipzig GmbH</b></p>
                            <p>Pfaffendorfer Straße 18<br/>
                                04105 Leipzig<br/>
                                Germany<br/>
                                email: info@n4.de</p>
                            <p>
                                <i>Vertreten durch:</i> <br/>
                                Geschäftsführer Kevin Dewi, Mesut Cengiz
                            </p>
                            <br/>
                                <h2><em></em>Datenschutzbeauftragter:</h2>
                                <p>Rechtsanwalt Stefan Wiesen | Mainzer Straße 161 | 66121 Saarbrücken</p>
                                <h2>Kontakt:</h2>
                                <p>phone: +49 681 63 89 7 - 0<br/>
                                    email: info@n4.de</p>
                                <h2>Registereintragungen:</h2>
                                <p><strong>netRapid GmbH & Co. KG</strong><br/>
                                    Amtsgericht Saarbrücken HRA 10590 / USt.-ID-Nr.: DE261836569</p>
                                <p><strong>n-Systems GmbH & Co. KG</strong><br/>
                                    Amtsgericht Saarbrücken, HRA 10714 / USt.-ID-Nr.: DE274485223</p>
                                <p><strong> NEXNOVA Business Solutions GmbH & Co. KG</strong><br/>
                                    Amtsgericht Saarbrücken, HRA 11076 / USt.-ID-Nr.: DE278352925</p>
                                <p><strong>NEXNOVA Software Solutions GmbH & Co. KG</strong><br/>
                                    Amtsgericht Saarbrücken, HRA 11075 / USt.-ID-Nr.: DE279005927</p>
                                <p><strong>N4 Leipzig GmbH</strong><br/>
                                    Amtsgericht Leipzig, HRB 35642 / USt.-ID-Nr.: DE321416615</p>
                </div>
                <Footer context="impressum"/>
            </div>
        );
    }
}

export default Impressum;
