import React from 'react';
import Testbutton from '../Testbutton/Testbutton';
import { Link, animateScroll as scroll } from 'react-scroll';

const navigation = ( props ) => {
    return (
        ( !props.context ) ?
            <div className={ 'navigation-wrapper ' + props.context }>
                <div className="d-block d-md-none">
                    <nav className="navbar navbar-light light-blue lighten-4">
                        <a className="navbar-brand" href="/">
                            <img className="navigationlogo" src="/public/Navigation.png" alt="" height="22"/>
                        </a>
                        <button className="navbar-toggler toggler-example" type="button" data-toggle="collapse"
                                data-target="#navbarSupportedContent1"
                                aria-controls="navbarSupportedContent1" aria-expanded="false"
                                aria-label="Toggle navigation"><span className="dark-blue-text"><i
                            className="fas fa-bars fa-1x"></i></span></button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent1">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item active">
                                    <Link to="description" className="nav-link" smooth={ true } offset={ -70 } duration={ 500 }>Funktionen</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="reasons" className="nav-link" smooth={ true } offset={ -70 } duration={ 500 }>Details</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="pricing" className="nav-link" smooth={ true } offset={ -70 } duration={ 500 }>Preise</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="contact" className="nav-link" smooth={ true } offset={ -70 } duration={ 500 }>Kontakt</Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <div className="container d-none d-md-block">
                    <div className="row">
                        <div className="col-sm-4 col-xl-3">
                            <div className="navi_light">
                                <nav className="navbar navbar-expand-sm bg-dark navbar-dark sticky-top navi">
                                    <a className="navbar-brand" href="/">
                                        <img className="navigationlogo" src="/public/Navigation.png" alt="" height="22"/>
                                    </a>
                                </nav>
                            </div>
                        </div>
                        <div className="col-sm-8 col-xl-9">
                            <nav className="navbar navbar-expand-sm bg-dark navbar-dark sticky-top navi">
                                <ul className="navbar-nav nav-right">
                                    <li className="nav-item">
                                        <Link to="description" className="nav-link" smooth={ true } offset={ -70 } duration={ 500 }>Funktionen</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="reasons" className="nav-link" smooth={ true } offset={ -70 } duration={ 500 }>Details</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pricing" className="nav-link" smooth={ true } offset={ -70 } duration={ 500 }>Preise</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="contact" className="nav-link" smooth={ true } offset={ -70 } duration={ 500 }>Kontakt</Link>
                                    </li>
                                </ul>
                                <Testbutton context={ 'navigation ' + props.context }/>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div className={ 'navigation-wrapper ' + props.context }>
                <div className="d-block d-md-none">
                    <nav className="navbar navbar-light light-blue lighten-4">
                        <a className="navbar-brand" href="/">
                            <img className="navigationlogo" src="/public/Navigation.png" alt="" height="22"/>
                        </a>
                        <button className="navbar-toggler toggler-example" type="button" data-toggle="collapse"
                                data-target="#navbarSupportedContent1"
                                aria-controls="navbarSupportedContent1" aria-expanded="false"
                                aria-label="Toggle navigation"><span className="dark-blue-text"><i
                            className="fas fa-bars fa-1x"></i></span></button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent1">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item active">
                                    <a href="/#description>" className="nav-link">Funktionen</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/#reasons" className="nav-link">Details</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/#pricing" className="nav-link">Preise</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/#contact" className="nav-link">Kontakt</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <div className="container d-none d-md-block">
                    <div className="row">
                        <div className="col-sm-4 col-xl-3">
                            <div className="navi_light">
                                <nav className="navbar navbar-expand-sm bg-dark navbar-dark sticky-top navi">
                                    <a className="navbar-brand" href="/">
                                        <img className="navigationlogo" src="/public/Navigation.png" alt="" height="22"/>
                                    </a>
                                </nav>
                            </div>
                        </div>
                        <div className="col-sm-8 col-xl-9">
                            <nav className="navbar navbar-expand-sm bg-dark navbar-dark sticky-top navi">
                                <ul className="navbar-nav nav-right">
                                    <li className="nav-item">
                                        <a href="/#description" className="nav-link">Funktionen</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="/#reasons" className="nav-link">Details</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="/#pricing" className="nav-link">Preise</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="/#contact" className="nav-link">Kontakt</a>
                                    </li>
                                </ul>
                                <Testbutton context={ 'navigation ' + props.context }/>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default navigation;
