import React, { Component } from 'react';
import Footer from './Footer/Footer';
import Navigation from './Navigation/Navigation';

class Impressum extends Component {
    constructor( props ) {
        super( props );
        this.state = {};
    }

    render() {
        return (
            <div className="impressum-wrapper">
                <Navigation context="datenschutz"/>
                <div className="container">
                    <div className="wrapper">
                        <div className="area-textblock container privacy-imprint">
                            <h2>Datenschutz­erklärung</h2>
                            <h5 className="text-highlight"> 1. Allgemeine Information zur Erhebung personenbezogener Daten</h5>
                            <p>Im Folgenden informieren wir Sie über die Erhebung personenbezogener Daten bei Nutzung unserer Website und wenn
                                Sie Kontakt mit uns aufnehmen. Personenbezogene Daten sind alle Daten, die auf Sie persönlich beziehbar sind, z.
                                B. Name, Adresse, E-Mail-Adressen, Nutzerverhalten.</p>
                            <h5>Verantwortliche</h5>
                            <p>Der Internetauftritt der N4 ist ein Angebot aller verbundenen Unternehmen. Gemeinsame Verantwortliche gem. Art. 4
                                Abs. 7 EU-Datenschutz-Grundverordnung (DSGVO) sind</p>
                            <h5>netRapid GmbH & Co. KG, vertreten durch netRapid Geschäftsführungs GmbH,</h5>
                            <h5>n-Systems GmbH & Co. KG, vertreten durch n-Systems Geschäftsführungs GmbH,</h5>
                            <h5>NEXNOVA Business GmbH & Co. KG, vertreten durch nexnova Geschäftsführungs GmbH,</h5>
                            <h5>NEXNOVA Software GmbH & Co. KG, vertreten durch nexnova Geschäftsführungs GmbH</h5>
                            <p>gesetzlicher Vertreter: Geschäftsführer jeweils Mesut Cengiz</p>
                            <h5>Gemeinsame Anschrift</h5>
                            <p>Kaiserstraße 95-101a<br />
                                66133 Saarbrücken<br />
                                Germany<br />
                                email info@n4.de</p>
                            <h5 className="mt-5">N4 Leipzig GmbH</h5>
                            <p>gesetzlicher Vertreter: Geschäftsführer Kevin Dewi, Mesut Cengiz</p>
                            <p>Pfaffendorfer Straße 18<br />
                                04105 Leipzig<br />
                                Germany<br />
                                email info@n4.de</p>
                            <h5>Datenschutzbeauftragter</h5>
                            <p>RA Stefan Wiesen<br />
                                datenschutz@ra-wiesen.de oder unter unserer Anschrift mit dem Zusatz „Der Datenschutzbeauftragte“</p>
                            <h5>Einfache Kontaktaufnahme</h5>
                            <p>Bei Ihrer Kontaktaufnahme mit uns per E-Mail, per Telefon, per Telefax, über ein Kontaktformular oder eine
                                Rückrufbitte werden die von Ihnen mitgeteilten Daten (z.B. Ihre E-Mail-Adresse, Ihr Name, Ihre Telefonnummer)
                                von uns gespeichert, um Ihre Fragen zu beantworten bzw. Ihr Anliegen zu bearbeiten. Rechtsgrundlagen hierfür
                                sind Art. 6 Abs. 1 S. 1 lit. a, b und f DSGVO, d.h. die Verarbeitung beruht auf Ihrer freiwillig erteilten
                                Einwilligung oder ist erforderlich zur Durchführung vorvertraglicher Maßnahmen oder zur Wahrung von
                                berechtigten Interessen. Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung
                                nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, falls eine Löschung wegen bestehender
                                Aufbewahrungspflichten noch nicht in Betracht kommt.</p>
                            <h5>Sicherheit</h5>
                            <p>Unser Internetauftritt nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die
                                Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung
                                erkennen Sie daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem
                                Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten,
                                die Sie an uns übermitteln, nicht von Dritten mitgelesen werden. Wir weisen gleichwohl darauf hin, dass die
                                Datenübertragung im Internet (insbesondere bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
                                kann.</p>
                            <h2 className="text-highlight">2. Ihre Rechte</h2>
                            <p>Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:<br />
                                Recht auf Auskunft gemäß Art. 15 DSGVO<br />
                                Sie haben insbesondere ein Recht auf Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten, die
                                Verarbeitungszwecke, die Kategorien der verarbeiteten personenbezogenen Daten, die Empfänger oder Kategorien
                                von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer bzw. die
                                Kriterien für die Festlegung der Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung,
                                Einschränkung der Verarbeitung, Widerspruch gegen die Verarbeitung, Beschwerde bei einer Aufsichtsbehörde, die
                                Herkunft Ihrer Daten, wenn diese nicht durch uns bei Ihnen erhoben wurden, das Bestehen einer automatisierten
                                Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftige Informationen über die involvierte Logik
                                und die Sie betreffende Tragweite und die angestrebten Auswirkungen einer solchen Verarbeitung, sowie Ihr Recht
                                auf Unterrichtung, welche Garantien gemäß Art. 46 DSGVO bei Weiterleitung Ihrer Daten in Drittländer bestehen;
                            </p>
                            <h5>Recht auf Berichtigung oder Löschung gemäß Art. 16, 17 DSGVO</h5>
                            <p>Sie haben ein Recht auf unverzügliche Berichtigung Sie betreffender unrichtiger Daten und/oder Vervollständigung
                                Ihrer bei uns gespeicherten unvollständigen Daten, und Sie haben das Recht, die Löschung Ihrer
                                personenbezogenen Daten bei Vorliegen der Voraussetzungen des Art. 17 Abs. 1 DSGVO zu verlangen. Dieses Recht
                                besteht jedoch insbesondere dann nicht, wenn die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung
                                und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur
                                Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;</p>
                            <h5>Recht auf Einschränkung der Verarbeitung gemäß Art. 18 DSGVO</h5>
                            <p>Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, solange die
                                von Ihnen bestrittene Richtigkeit Ihrer Daten überprüft wird, wenn Sie eine Löschung Ihrer Daten wegen
                                unzulässiger Datenverarbeitung ablehnen und stattdessen die Einschränkung der Verarbeitung Ihrer Daten
                                verlangen, wenn Sie Ihre Daten zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen,
                                nachdem wir diese Daten nach Zweckerreichung nicht mehr benötigen oder wenn Sie Widerspruch aus Gründen Ihrer
                                besonderen Situation eingelegt haben, solange noch nicht feststeht, ob unsere berechtigten Gründe
                                überwiegen;</p>
                            <h5>Recht auf Widerspruch gegen die Verarbeitung</h5>
                            <p>Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die Interessenabwägung stützen, können Sie
                                Widerspruch gegen die Verarbeitung einlegen. Dies ist der Fall, wenn die Verarbeitung insbesondere nicht zur
                                Erfüllung eines Vertrags mit Ihnen erforderlich ist. Bei Ausübung eines solchen Widerspruchs bitten wir um
                                Darlegung der Gründe, weshalb wir Ihre personenbezogenen Daten nicht wie von uns durchgeführt verarbeiten
                                sollten. Im Falle Ihres begründeten Widerspruchs werden wir entweder die Datenverarbeitung einstellen bzw.
                                anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung
                                fortführen.</p>
                            <p>Soweit wir uns bei der Verarbeitung ihrer personenbezogenen Daten auf Art.6 Abs.1 lit.f DSGVO als Rechtsgrundlage
                                berufen, kommen insbesondere folgende berechtigte Interessen in Betracht:<br />
                                Beantwortung von Anfragen, Durchführung von Direktmarketingmaßnahmen, die Bereitstellung von Diensten und/oder
                                Informationen, die für Sie bestimmt sind, Verarbeitung und Übertragung von personenbezogenen Daten für interne
                                bzw. administrative Zwecke, Betrieb und Verwaltung unserer Website, technischer Support der Nutzer, Vermeidung
                                und Aufdeckung von Betrugsfällen und Straftaten, Gewährleistung der Netzwerk- und Datensicherheit.<br />
                                Selbstverständlich können Sie auch der Verarbeitung Ihrer personenbezogenen Daten für Zwecke der Werbung und
                                Datenanalyse jederzeit widersprechen. Über Ihren Werbewiderspruch können Sie uns unter den oben angegebenen
                                Kontaktdaten informieren.<br />
                                Recht auf Datenübertragbarkeit</p>
                            <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
                                automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
                                aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
                                verlangen, erfolgt dies nur, soweit es technisch machbar ist.<br />
                                Widerruf einer erteilten Einwilligung<br />
                                Falls Sie eine Einwilligung zur Verarbeitung Ihrer Daten erteilt haben, können Sie diese jederzeit widerrufen.
                                Ein solcher Widerruf beeinflusst die Zulässigkeit der Verarbeitung Ihrer personenbezogenen Daten, nachdem Sie
                                ihn gegenüber uns ausgesprochen haben.<br />
                                Beschwerderecht<br />
                                Schließlich haben Sie das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer
                                personenbezogenen Daten durch uns zu beschweren. Die für uns zuständige Aufsichtsbehörde ist das Unabhängige
                                Datenschutzzentrum Saarland, Fritz-Dobisch-Straße 12, 66111 Saarbrücken.</p>
                            <h2 className="text-highlight">3. Erhebung von Daten bei Besuch unserer Website</h2>
                            <h5>Technische Daten</h5>
                            <p>Bei der bloß informatorischen Nutzung der Website, also wenn Sie sich nicht registrieren oder uns anderweitig
                                Informationen übermitteln, erheben wir die personenbezogenen Daten, die Ihr Browser an unseren Server
                                übermittelt. Wenn Sie unsere Website betrachten möchten, erheben wir die folgenden Daten, die für uns technisch
                                erforderlich sind, um Ihnen unsere Website anzuzeigen und die Stabilität und Sicherheit zu gewährleisten:<br />
                                – IP-Adresse<br />
                                – Datum und Uhrzeit der Anfrage<br />
                                – Zeitzonendifferenz zur Greenwich Mean Time (GMT)<br />
                                – Inhalt der Anforderung (konkrete Seite)<br />
                                – Zugriffsstatus/HTTP-Statuscode<br />
                                – jeweils übertragene Datenmenge<br />
                                – Website, von der die Anforderung kommt<br />
                                – Browser<br />
                                – Betriebssystem und dessen Oberfläche<br />
                                – Sprache und Version der Browsersoftware.<br />
                                Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO.</p>
                            <h5>Erhebung von Daten durch Kontaktformular</h5>
                            <p>Auf unserer Webseite ist ein Kontaktformular eingebunden. Machen Sie von dieser Möglichkeit Gebrauch, so werden
                                die in der Eingabemaske eingegebenen Daten an uns übermittelt und gespeichert. Diese Daten sind: Name, Firmenname,
                                Mailadresse, Telefonnummer, etc. Im Zeitpunkt der Absendung der Nachricht werden zudem folgende Daten
                                gespeichert: Die IP-Adresse, Datum und Uhrzeit. Für die weitere Verarbeitung der Daten wird beim Absenden Ihre
                                Einwilligung eingeholt und auf diese Datenschutzerklärung verwiesen.</p>
                            <p>Alternativ ist eine Kontaktaufnahme auch über die bereitgestellte E-Mail-Adresse möglich. In diesem Fall werden
                                die übermittelten personenbezogenen Daten gespeichert. Es erfolgt in diesem Zusammenhang keine Weitergabe der
                                Daten an Dritte. Die Daten werden ausschließlich für die Verarbeitung der Konversation verwendet.</p>
                            <p><strong>Rechtsgrundlage</strong> für die Verarbeitung von Daten, die per Kontaktformular oder E-Mail übermittelt
                                werden ist Art.6 Abs.1 lit.f DSGVO bzw. Art.6 Abs.1 lit.b DSGVO, wenn die Kontaktaufnahme auf den Abschluss
                                eines Vertrages abzielt.</p>
                            <p>Die Verarbeitung der personenbezogenen Daten aus der Eingabemaske dient uns allein zur Bearbeitung der
                                Kontaktaufnahme. Im Falle einer Kontaktaufnahme per E-Mail liegt hieran auch das erforderliche berechtigte
                                Interesse an der Verarbeitung der Daten. Die sonstigen ggf. während des Absendens verarbeiteten
                                personenbezogenen Daten dienen dazu, einen Missbrauch des Kontaktformulars zu verhindern und die Sicherheit
                                unserer informationstechnischen Systeme sicherzustellen.</p>
                            <p>Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind.
                                Für die personenbezogenen Daten aus der Eingabemaske des Kontaktformulars und diejenigen, die per E-Mail
                                übersandt wurden, ist dies dann der Fall, wenn die jeweilige Konversation mit dem Nutzer beendet ist. Beendet
                                ist die Konversation dann, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt
                                abschließend geklärt ist. Die während des Absendens zusätzlich erhobenen personenbezogenen Daten werden
                                spätestens nach einer Frist von sieben Tagen gelöscht.<br />
                                Willigen Sie in die Weitergabe Ihrer Daten zum Zwecke der Betreuung und Werbung ein, haben Sie jederzeit die
                                Möglichkeit, Ihre Einwilligung zu widerrufen.</p>

                            <h2 className="text-highlight">4. Weitergabe von Daten</h2>
                            <p>Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den vorstehend genannten Zwecken findet
                                nicht statt. Wir geben – zusammenfassend – Ihre persönlichen Daten nur an Dritte weiter, wenn Sie hierzu nach
                                Art. 6 Abs. 1 S. 1 lit. a DSGVO Ihre Einwilligung erteilt haben, dies nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für
                                die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist, dies zur Wahrung unserer berechtigten
                                Interessen nach Art. 6 Abs. 1 S. 1 lit. f DSGVO (z.B. zur Geltendmachung, Ausübung oder Verteidigung von
                                Rechtsansprüchen oder im Rahmen von Werbemaßnahmen) erforderlich ist und kein Grund zur Annahme besteht, dass
                                Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben oder wenn eine
                                gesetzliche Verpflichtung nach Art. 6 Abs. 1 S. 1 lit. c DSGVO zur Weitergabe besteht.</p>
                            <h2 className="text-highlight">5. Aktualität und Änderung dieser Datenschutzerklärung</h2>
                            <p>Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Oktober 2020, sie unterliegt aber der stetigen
                                Weiterentwicklung. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf unserem Internetauftritt
                                abgerufen werden.</p>
                        </div>


                    </div>
                </div>
                <Footer context="datenschutz"/>
            </div>
        );
    }
}

export default Impressum;
