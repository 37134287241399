import React from 'react';

const contactimage = () => {
    return (
        <div className="contactimage-wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" width="580.563" height="565.573"
                 viewBox="0 -0 580.563 565.573">
                <defs>
                    <linearGradient id="contactA" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#beeada"/ >
                        <stop offset="1" stopColor="#5cb8b2"/ >
                    </linearGradient>
                    <linearGradient id="contactB" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#f0faf7"/ >
                        <stop offset="1" stopColor="#beeada"/ >
                    </linearGradient>
                    <linearGradient id="contactC" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#d062e5"/ >
                        <stop offset="1" stopColor="#cd53e4"/ >
                    </linearGradient>
                    <linearGradient id="contactD" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#f98bec"/ >
                        <stop offset="1" stopColor="#e950e6"/ >
                    </linearGradient>
                </defs>
                <g transform="translate(-253.373 -128.792)">
                    <circle id="contactid1" className="k" cx="3.784" cy="3.784" r="3.784" transform="translate(292.496 405.495)"/>
                    <circle id="contactid2" className="k" cx="9.091" cy="9.091" r="9.091" transform="translate(306.255 301.539)"/>
                    <circle id="contactid3" className="k" cx="2.421" cy="2.421" r="2.421" transform="translate(349.223 380.173)"/>
                    <circle id="contactid4" className="k" cx="4.53" cy="4.53" r="4.53" transform="translate(253.373 246.479)"/>
                    <circle id="contactid5" className="k" cx="5.028" cy="5.028" r="5.028" transform="translate(403.025 28.792)"/>
                    <circle id="contactid6" className="k" cx="2.292" cy="2.292" r="2.292" transform="translate(467.415 170.964)"/>
                    <circle id="contactid7" className="k" cx="1.712" cy="1.712" r="1.712" transform="translate(335.383 161.648)"/>
                    <circle id="contactid8" className="k" cx="2.03" cy="2.03" r="2.03" transform="translate(351.644 220.688)"/>
                    <path className="a"
                          d="M598.079,727.513l-10.965,4.642L580,734.873a9.268,9.268,0,0,1-8.644-1.083l-20.539-14.48L496.88,681.428l16.627-3.779,20.92-18.3,3.5-3.059-100.389-54.36c-6.367-3.447-5.568-9.469,1.783-13.45l12.142-6.575,89.5-48.077,2.259-1.223.169.092a32.1,32.1,0,0,1,14.185-2.053h0l29.665.22h0a23.874,23.874,0,0,1,11.743,2.311L859.848,681.647c6.367,3.448,5.568,9.469-1.783,13.45l-94.1,51.523c-5.683,2.749-8.082,3.885-14.867,6.678l-19.253,8.544c-4.031,0-9.321-2.122-12.2-3.679L632.06,708.449l-21.222,12.381Z"
                          transform="translate(-30.21 -67.478)"/>
                    <path className="b"
                          d="M643.01,720.761l-54.375,31.82a9.336,9.336,0,0,1-9.388.006l-65.395-37.973a9.337,9.337,0,0,1,.02-16.161l41.523-23.974Z"
                          transform="translate(-42.97 -91.655)"/>
                    <path className="c" d="M627.328,647.136l22.9,58.524-84.331-48.348-11.976-52.567Z"
                          transform="translate(-50.48 -79.942)"/>
                    <path className="b" d="M825.362,734l-20.64,11.166,2.812-26.491h13.446Z" transform="translate(-92.606 -99.078)"/>
                    <path className="b" d="M401.793,206.7l17.867-9.759-2.293,19.8Z" transform="translate(-24.929 -11.447)"/>
                    <path className="b"
                          d="M431.935,461.59,750.92,645.757c7.566,4.368,13.7-.269,13.7-10.356V407.39c0-10.088-6.134-21.807-13.7-26.174L431.935,197.048c-7.566-4.367-13.7.269-13.7,10.356V435.416C418.236,445.5,424.369,457.222,431.935,461.59Z"
                          transform="translate(-27.691 -11.188)"/>
                    <path className="d"
                          d="M407.831,474.559,726.817,658.726c7.566,4.368,13.7-.269,13.7-10.356V420.359c0-10.088-6.133-21.806-13.7-26.174L407.831,210.018c-7.566-4.368-13.7.268-13.7,10.356V448.385C394.132,458.473,400.266,470.191,407.831,474.559Z"
                          transform="translate(-23.642 -13.367)"/>
                    <path className="e" d="M394.132,433.521,740.516,633.505V419.056L394.132,219.071Z"
                          transform="translate(-23.642 -15.164)"/>
                    <path className="b" d="M625.983,652.593l21.252,55.75L562.9,660l-8.988-49.277Z"
                          transform="translate(-50.48 -80.946)"/>
                    <path className="f"
                          d="M639.725,718.291l-51.09,29.753a9.338,9.338,0,0,1-9.388.006l-65.395-37.973a9.338,9.338,0,0,1,.02-16.161l41.523-23.973Z"
                          transform="translate(-42.97 -90.893)"/>
                    <path className="c" d="M667.251,721.414l-2.988,1.681L655.3,699.577l3.006-1.034Z"
                          transform="translate(-67.508 -95.697)"/>
                    <path className="c" d="M626.713,653.022l2.017,6.778-73.48-41.77-1.334-7.311Z"
                          transform="translate(-50.48 -80.946)"/>
                    <path className="b" d="M669.061,726.031l.3,3.389-3.284-1.708Z" transform="translate(-69.318 -100.314)"/>
                    <path className="g"
                          d="M575.558,242.547,469.831,271.826l-5.938,3.431.976.807,4.962-1.149v161.07L675.347,554.64V561.5l5.938-3.431V393.909Z"
                          transform="translate(-35.36 -19.107)"/>
                    <path className="g" d="M463.894,281.861,569.621,426.7l105.727-22.762Z" transform="translate(-35.36 -25.71)"/>
                    <path className="h" d="M675.347,398.033,569.621,246.67,463.894,275.95Z" transform="translate(-35.36 -19.799)"/>
                    <path className="i"
                          d="M647.161,490.565,500.014,405.609a3.713,3.713,0,0,1-1.856-3.215V232.486a3.712,3.712,0,0,1,5.569-3.215l147.147,84.954a3.713,3.713,0,0,1,1.855,3.215V487.35A3.712,3.712,0,0,1,647.161,490.565Z"
                          transform="translate(-41.115 -16.792)"/>
                    <path className="j"
                          d="M632.085,431.85,528.15,371.843a2.831,2.831,0,0,1-1.414-2.451h0a2.83,2.83,0,0,1,4.245-2.45l103.935,60.007a2.83,2.83,0,0,1,1.414,2.451h0A2.83,2.83,0,0,1,632.085,431.85Z"
                          transform="translate(-45.915 -39.936)"/>
                    <path className="j"
                          d="M632.085,348.992,528.15,288.985a2.831,2.831,0,0,1-1.414-2.451h0a2.83,2.83,0,0,1,4.245-2.451L634.916,344.09a2.829,2.829,0,0,1,1.414,2.45h0A2.83,2.83,0,0,1,632.085,348.992Z"
                          transform="translate(-45.915 -26.019)"/>
                    <path className="j"
                          d="M632.085,369.706,528.15,309.7a2.829,2.829,0,0,1-1.414-2.45h0a2.83,2.83,0,0,1,4.245-2.451L634.916,364.8a2.831,2.831,0,0,1,1.414,2.451h0A2.83,2.83,0,0,1,632.085,369.706Z"
                          transform="translate(-45.915 -29.498)"/>
                    <path className="j"
                          d="M632.085,390.421,528.15,330.414a2.831,2.831,0,0,1-1.414-2.451h0a2.83,2.83,0,0,1,4.245-2.451l103.935,60.007a2.829,2.829,0,0,1,1.414,2.45h0A2.83,2.83,0,0,1,632.085,390.421Z"
                          transform="translate(-45.915 -32.977)"/>
                    <path className="j"
                          d="M632.085,411.136,528.15,351.128a2.829,2.829,0,0,1-1.414-2.45h0a2.83,2.83,0,0,1,4.245-2.451l103.935,60.007a2.831,2.831,0,0,1,1.414,2.451h0A2.83,2.83,0,0,1,632.085,411.136Z"
                          transform="translate(-45.915 -36.457)"/>
                    <path className="h" d="M675.347,568.1,463.894,446.019V281.861l105.727,144.69,105.727-22.607Z"
                          transform="translate(-35.36 -25.71)"/>
                    <path className="g" d="M569.7,456.31,464.374,475.551l7.035,4.062L569.7,461.657l98.421,131.528,7.041,4.065Z"
                          transform="translate(-35.44 -55.011)"/>

                </g>
            </svg>
        </div>
    );
}

export default contactimage;
