import React from 'react';

const demoimage = () => {
    return (
        <div className="demoimage-wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" width="516.866" height="475.56" viewBox="0 0 500 500">
                <g className="a" transform="translate(0 0) rotate(0)">
                    <circle id="demobubble1" className="b" cx="13.5" cy="13.5" r="13.5" transform="translate(300 100) rotate(69)"/>
                    <circle id="demobubble2" className="c" cx="8.5" cy="8.5" r="8.5" transform="translate(100 400) rotate(69)"/>
                    <circle id="demobubble3" className="c" cx="3.5" cy="3.5" r="3.5" transform="translate(200 450) rotate(69)"/>
                    <circle id="demobubble4" className="c" cx="12" cy="12" r="12" transform="translate(150 300) rotate(69)"/>
                    <circle id="demobubble5" className="b" cx="13.5" cy="13.5" r="13.5" transform="translate(220 50) rotate(69)"/>
                    <circle id="demobubble6" className="b" cx="8.5" cy="8.5" r="8.5" transform="translate(100 150) rotate(69)"/>
                    <circle id="demobubble7" className="c" cx="8.5" cy="8.5" r="8.5" transform="translate(200 200) rotate(69)"/>
                    <circle id="demobubble8" className="b" cx="8.5" cy="8.5" r="8.5" transform="translate(222 222) rotate(69)"/>
                    <circle id="demobubble9" className="c" cx="3.5" cy="3.5" r="3.5" transform="translate(50 450) rotate(69)"/>
                    <circle id="demobubble10" className="c" cx="3.5" cy="3.5" r="3.5" transform="translate(330 250) rotate(69)"/>
                    <circle id="demobubble11" className="b" cx="8.5" cy="8.5" r="8.5" transform="translate(320 340) rotate(69)"/>
                    <circle id="demobubble12" className="c" cx="3.5" cy="3.5" r="3.5" transform="translate(250 100) rotate(69)"/>
                    <circle id="demobubble13" className="c" cx="8.5" cy="8.5" r="8.5" transform="translate(350 450) rotate(69)"/>
                    <circle id="demobubble14" className="c" cx="3.5" cy="3.5" r="3.5" transform="translate(70 222) rotate(69)"/>
                </g>
            </svg>
        </div>
    );
}

export default demoimage;
