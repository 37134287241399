import React, {Component} from 'react';

import Modal from './Modal/Modal';
import Hero from './Hero/Hero';
import Footer from './Footer/Footer';
import Description from './Description/Description';
import Demo from './Demo/Demo';
import Reasons from './Reasons/Reasons';
import Pricing from './Pricing/Pricing';
import Contact from './Contact/Contact';
import Navigation from './Navigation/Navigation';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.formfields = [
            'formname',
            'formemail',
            'formtext',
            'formphone',
            'formfirm'
        ];
        this.handleSubmit = ( e ) => {
            e.preventDefault();
            fetch(`/send`,{
                method: "POST",
                body: JSON.stringify(this.state),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then(
                (response) => (response.json())
            ).then((response)=>{
                if (response.status === 'success'){
                    document.getElementById('mailformular').reset();
                    alert("Ihre Anfrage wurde verschickt.");
                }else if(response.status === 'fail'){
                    alert("Fehler beim Versand der Anfrage.")
                }
            })
        };
    }
    componentDidMount() {
        // Formular auslesen

        try {
            this.formfields.forEach((item) => {
                let field = document.getElementById(item);
                field.onchange = () => {
                    let _state = this.state;
                    _state[item] = field.value;
                    this.setState( _state );
                };
            });
        } catch (e) {
            console.error("ERR", e);
        }
    }
    render() {
        return (
            <div className="home-wrapper">
                <Navigation/>
                <Hero/>
                <Description context="" />
                {/*<Demo/>*/}
                <Reasons/>
                <Pricing/>
                <Contact submitfn={ this.handleSubmit } />
                <Footer/>
                <Modal />
            </div>
        );
    }
}

export default Home;
