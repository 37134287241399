import React from 'react';

const modal = () => {
    return (
        <div className="modal-wrapper">
            <div
                className="modal fade"
                id="modal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="modalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="container-fluid">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true" id="closebutton">&times;</span>
                                        </button>
                                    </div>
                                    <div className="col-sm-12 col-md-6 modaltext">
                                        <div className="row">
                                            <div className="col-sm-8 offset-sm-2">
                                                <h3>RISIKOFREI TESTEN</h3>
                                                <hr className="hrleft"/>
                                                <p>
                                                    <b>Verlieren Sie keine Zeit (mehr)</b><br/>
                                                    Testen Sie zeit.report ganze vier Wochen kostenfrei mit
                                                    vollem Funktionsumfang und machen Sie Zeiterfassung
                                                    zum Kinderspiel. Sollten Sie nicht überzeugt sein, endet
                                                    Ihre Testversion ohne weitere Verpflichtungen.
                                                </p>
                                                <form action="#" method="post">
                                                    <fieldset>
                                                        <div className="form-group">
                                                            <div className="col-md-4">
                                                                <input
                                                                    name="textinput"
                                                                    type="text"
                                                                    placeholder="Name, Vorname"
                                                                    className="form-control input-md inputfield"
                                                                    required=""

                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="col-md-4">
                                                                <input
                                                                    name="textinput"
                                                                    type="text"
                                                                    placeholder="Email Adresse"
                                                                    className="form-control input-md inputfield"
                                                                    required=""
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="col-md-4">
                                                                <button
                                                                    name="singlebutton"
                                                                    className="btn form-control btn-primary gradientbutton"
                                                                >
                                                                    Kostenlosen Testaccount anfordern
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6">
                                        <div className="modalimage"
                                        >
                                            <svg className="bulb" xmlns="http://www.w3.org/2000/svg"
                                                 width="500" height="500"
                                                 viewBox="-50 -50 500 520">
                                                <defs>
                                                    <linearGradient id="a" y1="0.5" x2="1" y2="0.5"
                                                                    gradientUnits="objectBoundingBox">
                                                        <stop offset="0" stopColor="#d062e5"/>
                                                        <stop offset="1" stopColor="#cd53e4"/>
                                                    </linearGradient>
                                                    <linearGradient id="b" y1="0.5" x2="1" y2="0.5"
                                                                    gradientUnits="objectBoundingBox">
                                                        <stop offset="0" stopColor="#f98bec"/>
                                                        <stop offset="1" stopColor="#e950e6"/>
                                                    </linearGradient>
                                                    <linearGradient id="e" x1="-0.366" x2="1.366" href="#b"/>
                                                    <linearGradient id="g" y1="0.5" x2="1" y2="0.5"
                                                                    gradientUnits="objectBoundingBox">
                                                        <stop offset="0" stopColor="#fff"/>
                                                        <stop offset="1" stopColor="#beeada"/>
                                                    </linearGradient>
                                                    <linearGradient id="h" x1="-0.366" y1="0.5" x2="1.366" y2="0.5"
                                                                    gradientUnits="objectBoundingBox">
                                                        <stop offset="0" stopColor="#beeada"/>
                                                        <stop offset="1" stopColor="#5cb8b2"/>
                                                    </linearGradient>
                                                    <linearGradient id="i" x1="0" y1="0.5" x2="1" y2="0.5"
                                                                    href="#h"/>
                                                </defs>
                                                <g>
                                                    <circle id="Modalid1" className="a" cx="13.5" cy="13.5" r="13.5"
                                                            transform="translate(330 0)"/>
                                                    <circle id="Modalid2" className="b" cx="8.5" cy="8.5" r="8.5"
                                                            transform="translate(90 0)"/>
                                                    <circle id="Modalid3" className="b" cx="3.5" cy="3.5" r="3.5"
                                                            transform="translate(95 60)"/>
                                                    <circle id="Modalid4" className="b" cx="12" cy="12" r="12"
                                                            transform="translate(220 160)"/>
                                                    <circle id="Modalid5" className="a" cx="13.5" cy="13.5" r="13.5"
                                                            transform="translate(280 0)"/>
                                                    <circle id="Modalid6" className="a" cx="8.5" cy="8.5" r="8.5"
                                                            transform="translate(10 50)"/>
                                                    <circle id="Modalid7" className="b" cx="8.5" cy="8.5" r="8.5"
                                                            transform="translate(165 30)"/>
                                                    <circle id="Modalid8" className="a" cx="8.5" cy="8.5" r="8.5"
                                                            transform="translate(185 115)"/>
                                                    <circle id="Modalid9" className="b" cx="3.5" cy="3.5" r="3.5"
                                                            transform="translate(370 265)"/>
                                                    <circle id="Modalid10" className="a" cx="8.5" cy="8.5" r="8.5"
                                                            transform="translate(365 450)"/>
                                                    <circle id="Modalid11" className="b" cx="3.5" cy="3.5" r="3.5"
                                                            transform="translate(280 520)"/>
                                                    <circle id="Modalid12" className="b" cx="8.5" cy="8.5" r="8.5"
                                                            transform="translate(340 40)"/>
                                                    <circle id="Modalid13" className="b" cx="3.5" cy="3.5" r="3.5"
                                                            transform="translate(90 50)"/>
                                                    <defs>
                                                        <linearGradient id="a" y1="0.5" x2="1" y2="0.5"
                                                                        gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stopColor="#d062e5"/>
                                                            <stop offset="1" stopColor="#cd53e4"/>
                                                        </linearGradient>
                                                        <linearGradient id="b" y1="0.5" x2="1" y2="0.5"
                                                                        gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stopColor="#f98bec"/>
                                                            <stop offset="1" stopColor="#e950e6"/>
                                                        </linearGradient>
                                                        <linearGradient id="e" x1="-0.366" x2="1.366"/>
                                                        <linearGradient id="g" y1="0.5" x2="1" y2="0.5"
                                                                        gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stopColor="#fff"/>
                                                            <stop offset="1" stopColor="#beeada"/>
                                                        </linearGradient>
                                                        <linearGradient id="g2" y1="0.5" x2="1" y2="0.5"
                                                                        gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stopColor="#fff"/>
                                                            <stop offset="1" stopColor="#ddd"/>
                                                        </linearGradient>
                                                        <linearGradient id="h" x1="-0.366" y1="0.5" x2="1.366" y2="0.5"
                                                                        gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stopColor="#beeada"/>
                                                            <stop offset="1" stopColor="#5cb8b2"/>
                                                        </linearGradient>
                                                        <linearGradient id="i" x1="0" y1="0.5" x2="1" y2="0.5"/>
                                                        <filter id="light" width="200%" height="200%" x="-50%" y="-50%">
                                                            <feOffset in="SourceGraphic" dx="0" dy="0"
                                                                      result="offset"></feOffset>
                                                            <feComposite operator="in" in2="offset"
                                                                         result="stroke"></feComposite>
                                                            <feGaussianBlur stdDeviation="20"
                                                                            result="offsetblur"></feGaussianBlur>
                                                            <feFlood floodColor="#ffffff"></feFlood>
                                                            <feComposite operator="in" in2="offsetblur"
                                                                         result="blur"></feComposite>
                                                            <feMerge>
                                                                <feMergeNode in="blur"></feMergeNode>
                                                                <feMergeNode in="SourceGraphic"></feMergeNode>
                                                            </feMerge>
                                                        </filter>
                                                    </defs>

                                                </g>
                                                <g transform="translate(-848.362 -199.456)">
                                                    <g transform="translate(925.468 500.713)">
                                                        <path className="a"
                                                              d="M939.811,380.592c-12.049,6.958-31.646,6.926-43.771-.074s-12.187-18.315-.138-25.274,31.646-6.926,43.771.074S951.858,373.632,939.811,380.592Z"
                                                              transform="translate(-886.905 -350.046)"/>
                                                    </g>
                                                    <g transform="translate(912.204 407.352)">
                                                        <path className="b"
                                                              d="M968.418,395.662l-88.144,1.393V303.377h88.144Z"
                                                              transform="translate(-880.275 -303.377)"/>
                                                    </g>
                                                    <g transform="translate(912.205 474.97)">
                                                        <path className="b"
                                                              d="M955.556,380.759c-17.2,10.025-45.114,10.089-62.344.142s-17.252-26.137-.052-36.163,45.111-10.089,62.342-.14S972.756,370.735,955.556,380.759Z"
                                                              transform="translate(-880.275 -337.178)"/>
                                                    </g>
                                                    <g transform="translate(912.346 421.479)">
                                                        <path className="a"
                                                              d="M968.491,321.816c-.624-.394-1.264-.784-1.924-1.164-23.488-13.56-61.452-13.625-84.792-.144-.488.282-.96.568-1.428.856v47.084c.552.344,1.114.686,1.7,1.022,23.488,13.56,61.45,13.623,84.792.142.568-.326,1.116-.66,1.656-1Z"
                                                              transform="translate(-880.346 -310.439)"/>
                                                    </g>
                                                    <g transform="translate(896.021 412.069)">
                                                        <ellipse className="c" cx="34.62" cy="59.958" rx="34.62"
                                                                 ry="59.958"
                                                                 transform="translate(0 69.24) rotate(-89.698)"/>
                                                    </g>
                                                    <g transform="translate(896.207 425.949)">
                                                        <rect className="b" width="119.912" height="22.104"/>
                                                    </g>
                                                    <g transform="translate(896.021 391.013)">
                                                        <ellipse className="d" cx="34.62" cy="59.958" rx="34.62"
                                                                 ry="59.958"
                                                                 transform="translate(0 69.24) rotate(-89.698)"/>
                                                    </g>
                                                    <g transform="translate(917.725 392.947)">
                                                        <ellipse className="f" cx="21.52" cy="37.27" rx="21.52"
                                                                 ry="37.27"
                                                                 transform="translate(0 43.039) rotate(-89.697)"
                                                        />
                                                    </g>
                                                    <g transform="translate(912.366 288.538)">
                                                        <path className="g"
                                                              d="M929.487,378.221a2.8,2.8,0,0,1-2.8-2.8l-.014-73.251c-.32-.052-.642-.106-.962-.162a61.242,61.242,0,0,1-10.365-2.793v69.8a2.8,2.8,0,0,1-5.6,0V296.858a60.9,60.9,0,0,1-15.622-10.723c-7.756-7.378-12.721-16.114-13.624-23.97-.878-7.636,2.275-13.577,8.652-16.3,6.088-2.6,11.381-2.5,15.736.3,9.9,6.37,10.437,24.67,10.455,25.446l0,.066v21.574a56.5,56.5,0,0,0,11.337,3.243c.126.022.25.044.374.064,1.732-19.7,6-31.4,12.983-35.677,3.767-2.3,8.126-2.413,12.955-.318,9.961,4.321,14.732,13.369,12.157,23.054-2.583,9.7-13.035,19.225-30.272,19.227q-1.278,0-2.609-.072l.014,72.647A2.8,2.8,0,0,1,929.487,378.221Zm3.135-81.035c15.65.824,24.984-6.99,27.119-15.008,1.852-6.96-1.586-13.273-8.972-16.476-3.1-1.346-5.655-1.36-7.8-.044C939.227,267.946,934.524,275.338,932.622,297.186Zm-35.285-47.6a15.425,15.425,0,0,0-5.986,1.424c-5.119,2.189-5.7,6.924-5.289,10.513,1.014,8.818,9.452,21.418,23.684,29.1V271.715c-.118-4.491-1.76-16.9-7.892-20.843A8.158,8.158,0,0,0,897.337,249.588Z"
                                                              transform="translate(-880.356 -243.986)"/>
                                                    </g>
                                                    <g className="h" transform="translate(862.541 237.525)">
                                                        <ellipse className="d" cx="38.199" cy="27.602" rx="38.199"
                                                                 ry="27.602"
                                                                 transform="translate(0 68.81) rotate(-64.247)"/>
                                                    </g>
                                                    <g transform="translate(848.362 199.456)">
                                                        <path className="e"
                                                              d="M1063.961,298.521c0-54.714-48.264-99.065-107.8-99.065s-107.8,44.351-107.8,99.065c0,29.958,14.472,56.808,37.339,74.973,12.511,13.756,18.229,30.682,20.837,42.925a34.408,34.408,0,0,0,22.524,25.4c18.569,6.382,36.491,7.164,53.58.728a33.783,33.783,0,0,0,21.119-24.878c2.341-11.443,7.828-27.933,20.705-42.091.37-.406.7-.8,1-1.188C1049,356.216,1063.961,328.975,1063.961,298.521Z"
                                                              transform="translate(-848.362 -199.456)"
                                                        />
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default modal;
