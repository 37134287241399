import React from 'react';
import Testbutton from '../Testbutton/Testbutton';
import Heroimage from './Heroimage/Heroimage';
import { Link } from 'react-scroll';

const hero = () => {
    return (
        <div className="hero-wrapper">
            <div className={ 'hero-image-wrapper' }>
                <img src={ '/public/HeroBG.png' } className={ 'hero-image d-none d-md-block' }/>
                <div className={ 'content-wrapper' }>
                    <div className="container middlecontent">
                        <div className="row">
                            <div className="col-xs-12 col-md-5 col-xl-5 herotext">
                                <div>
                                    <h3>ZEITERFASSUNG<br/>LEICHT<br/>GEMACHT</h3>
                                    <hr className="hrleft"/>
                                    <p>
                                        Erfassen Sie Arbeitszeiten intuitiv, vollständig und rechtskonform. Als
                                        cloudbasiertes Programm
                                        ohne erforderliche Zusatz-Hardware ist zeit.report ausgesprochen erschwinglich
                                        und flexibel.
                                    </p>
                                    <p>
                                        Zeiterfassung sollte vor allem eines nicht kosten: Zeit. Daher ist zeit.report
                                        eine Zeiterfassungssoftware mit allen denkbaren Funktionen,
                                        aber ohne unnötigen Ballast.
                                    </p>
                                    <Link to="contact" className="nav-link" smooth={ true } offset={ -70 } duration={ 500 }>
                                        <button className="btn form-control btn-primary gradientbutton">
                                            Kontaktieren Sie uns!
                                        </button>
                                    </Link>
                                    <Testbutton/>
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-7 col-xl-7">
                                <Heroimage/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default hero;
