import React from 'react';

const testbutton = (props) => {
    return false;
    return (
        <div className="testbutton-wrapper">
            <button type="button" className={ "btn btn-primary gradientbutton " + props.context } data-toggle="modal" data-target="#modal">
                testen
            </button>
        </div>
    )
}

export default testbutton;
